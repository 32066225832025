import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const config = {
  apiKey: "AIzaSyAWb5JxaiaUbrcCpLn1Q4sFIfewbR4BoGk",
  authDomain: "sav-portal-dev.firebaseapp.com",
  projectId: "sav-portal-dev",
  storageBucket: "sav-portal-dev.appspot.com",
  messagingSenderId: "763700850713",
  appId: "1:763700850713:web:5c60e0c5ea7993bd2eba88",
};

!firebase.apps.length ? firebase.initializeApp(config) : "";

export default firebase;
